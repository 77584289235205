import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import CurrencyFormat from 'react-currency-format';
import axios from 'axios';
import moment from 'moment';
import {API_URL, config} from '../../config/baseUrl';

import './TransactionDetail.css';

export default class TransactionDetail extends Component {

   state = {
      transactionData: {},
      loading: true,
      isDialogOpen: false,
      dialogKey: '',
      colorBG: '',
      amount: 0,
      activityLoading: false
   }

   componentDidMount(){
      this.getTransData(); 
   }

  
   getTransData = async () => {
         try {
            const response = await axios.get(API_URL + '/user/transactions/data/' + this.props.match.params.id, config)
            if (response.data.message === "success") {
               // console.log('transactionData', response.data);
                this.setState({transactionData: response.data.transData, loading:false})
            }else this.setState({loading: false})
         } catch (error) {
              console.log({error});
         }
   }


  reverseTransactionFunc = (route) => {
         const isConfirmed = window.confirm(route == "revert-transaction" ? "Do you want to reverse this transaction?" :
            "Have you confirmed transaction was successful?");
         if(!isConfirmed) return null;
         this.setState({activityLoading: true})
         const USER_TOKEN = localStorage.getItem('token');
         var config = {
            headers: {'Authorization': "bearer " + USER_TOKEN}
         };
         
         var bodyParameters = {
               TRANS_ID: this.state.transactionData.TRANS_ID
         }
     
     axios.post(API_URL + 
        `/admin/${route}`,
        bodyParameters,
        config
        ).then((response) => {
           if (response.data.message === "success") {
              ////.log(response.data);
              window.location.reload();
           }

           else if (response.data.message === "blocked") {
              this.logOut()
           }
        }).catch((error) => {
           //.log(error)
           this.setState({activityLoading: false})
           alert("network error")
     });
    
  }

  logOut = () => {
   //   localStorage.clear();
   //    window.location.reload();
   }


  openDialog = (dialogKey, colorBG) => {
     this.setState({ isDialogOpen: true, dialogKey, colorBG })
  }

   handleClose = () => this.setState({ isDialogOpen: false })


    render() {
       const {transactionData, dialogKey, isDialogOpen, loading, colorBG} = this.state;
       const adminData =  JSON.parse(localStorage.getItem("userData" + API_URL));
        return (
            loading ? 
            <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
               <Spinner color="#47c494" size={30}/>   
            </div>          
            :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Transaction detail</p>
                    
                </div>

                <div className="details-container">

                {isDialogOpen &&
                     <div className="dialog-box-container">
                     <p><span style={{color: colorBG}}>{  dialogKey + "  " }</span> TRANS-ID <span style={{color:'#a4dcb9', fontSize:20, fontWeight:600}}>{ "   " + transactionData.TRANS_ID }</span></p>
   
                    </div>
                   }

                    {transactionData.category === "transfer" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.isBold? "Bold Transfer" :  "Transfer to other banks"}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Transfer Type</p>
                          <p className="info-response">{transactionData.entry}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Name</p>
                          <p className="info-response">{transactionData.senderName}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.senderAccount}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Receiver Account Info</p>
                          {transactionData.isBold ?
                           <div>
                              <p className="info-response">Bank Name:  {transactionData.receiverMetaData.bankName}</p>
                              <p className="info-response">Receipient: {transactionData.receiverMetaData.beneficiaryName}</p>
                              <p className="info-response">Account Number: {transactionData.receiverMetaData.bankAccount}</p>
                           </div>
                              :
                           <div>
                               <p className="info-response">Bank Name:  {transactionData.receiverMetaData.bankName}</p>
                               <p className="info-response">Receipient: {transactionData.receiverMetaData.beneficiaryName}</p>
                               <p className="info-response">Account Number: {transactionData.receiverMetaData.bankAccount}</p>
                           </div>
                          }
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                             prefix={transactionData.currency} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.timeStamp).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.ref &&
                          <div className="user-data-info">
                          <p className="info-title">Ref</p>
                          <p className="info-response">{transactionData.ref}</p>
                       </div>
                       }

                    </div>
                    }

                    {transactionData.category === "bill" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Bill Category</p>
                          <p className="info-response">{transactionData.billData.serviceCategrory}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Bill Variation</p>
                          <p className="info-response">{transactionData.billData.variationCode}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Bill serviceID</p>
                          <p className="info-response">{transactionData.billData.serviceID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Name</p>
                          <p className="info-response">{transactionData.senderName}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.senderAccount}</p>
                       </div> 

                       <div className="user-data-info">
                          <p className="info-title">Receiver Biller number</p>
                          <p className="info-response">{transactionData.billData.billerNumber}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                            suffix={transactionData.currency} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.date_initiated).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Comment</p>
                          <p className="info-response">{transactionData.comment}</p>
                       </div>

                       { transactionData.unique_transfer_code &&
                          <div className="user-data-info">
                          <p className="info-title">Unique Transfer Code</p>
                          <p className="info-response">{transactionData.unique_transfer_code}</p>
                       </div>
                       }

                    </div>
                    }


                    {transactionData.category === "funding" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.isBold? "Bold Funding" :  "Funding"}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Transfer Type</p>
                          <p className="info-response">{transactionData.entry}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Name</p>
                          <p className="info-response">{transactionData.senderName}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.senderAccount}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Receiver Account Number</p>
                          <p className="info-response">{transactionData.receiverAccount}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Info</p>
                          {transactionData.isBold ?
                           <div>
                              <p className="info-response bold-tx">Bank Name:  {transactionData.sourceMetaData.bank_name}</p>
                              <p className="info-response bold-tx">Sender Name: {transactionData.sourceMetaData.account_name}</p>
                              <p className="info-response bold-tx">Account Number: {transactionData.sourceMetaData.account_number}</p>
                           </div>
                              :
                           <div>
                              <p className="info-response">Bank Name:  {transactionData.sourceMetaData.bank_name}</p>
                              <p className="info-response">Sender Name: {transactionData.sourceMetaData.account_name}</p>
                              <p className="info-response">Account Number: {transactionData.sourceMetaData.account_number}</p>
                           </div>
                          }
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                             prefix={transactionData.currency} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.timeStamp).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.ref &&
                          <div className="user-data-info">
                          <p className="info-title">Ref</p>
                          <p className="info-response">{transactionData.ref}</p>
                       </div>
                       }

                    </div>
                    }

                    {transactionData.category === "fx" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.category}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Transfer Type</p>
                          <p className="info-response">{transactionData.entry}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Name</p>
                          <p className="info-response">{transactionData.senderName}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.senderAccount}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Fx Details</p>
                           <div>
                              <p className="info-response">fromCurrency:  {transactionData.fx.fromCurrency}</p>
                              <p className="info-response">toCurrency: {transactionData.fx.toCurrency}</p>
                              <p className="info-response">AmountExchanged: {transactionData.fx.fromCurrency + transactionData.fx.amountExchanged}</p>
                              <p className="info-response">AmountReceived: {transactionData.fx.toCurrency + transactionData.fx.amountReceived}</p>
                           </div>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                             prefix={transactionData.currency} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.timeStamp).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.ref &&
                          <div className="user-data-info">
                          <p className="info-title">Ref</p>
                          <p className="info-response">{transactionData.ref}</p>
                       </div>
                       }

                    </div>
                    }

                   {transactionData.category === "card" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.category}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Entry</p>
                          <p className="info-response">{transactionData.entry}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Name</p>
                          <p className="info-response">{transactionData.senderName}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.senderAccount}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Remarks</p>
                          <p className="info-response">{transactionData.comment}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                             prefix={transactionData.currency} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.timeStamp).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.ref &&
                          <div className="user-data-info">
                          <p className="info-title">Ref</p>
                          <p className="info-response">{transactionData.ref}</p>
                       </div>
                       }

                    </div>
                    }
                    {transactionData.category === "savings" && 
                       <div className="user-details-wrapper">
                       <div className="user-data-info">
                          <p className="info-title">Transaction Type</p>
                          <p className="info-response">{transactionData.category}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">TRANS_ID</p>
                          <p className="info-response">{transactionData.TRANS_ID}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Status</p>
                          <p className="info-response">{transactionData.status}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Entry</p>
                          <p className="info-response">{transactionData.entry}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Account Name</p>
                          <p className="info-response">{transactionData.senderName}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Sender Account Number</p>
                          <p className="info-response">{transactionData.senderAccount}</p>
                       </div>
                       

                       <div className="user-data-info">
                          <p className="info-title">Remarks</p>
                          <p className="info-response">{transactionData.comment}</p>
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Amount</p>
                          <CurrencyFormat value={transactionData.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                             prefix={transactionData.currency} />
                       </div>

                       <div className="user-data-info">
                          <p className="info-title">Time</p>
                          <div className="info-response">
                              <p style={{fontSize:13}} className="info-title">{moment(transactionData.timeStamp).format('DD MMM YYYY : h:m:s')}</p>
                          </div>
                       </div>

                       { transactionData.ref &&
                          <div className="user-data-info">
                          <p className="info-title">Ref</p>
                          <p className="info-response">{transactionData.ref}</p>
                       </div>
                       }

                    </div>
                    }
                   <div className="user-identity-card-wrapper">
                      
                   </div>
                </div>

            </div>
               
        )
    }

}
