import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import axios from 'axios';
import './AdminManagement.css'
import {API_URL, config} from '../../config/baseUrl';

export default class SideBar extends Component {

  state = {
    loading: !true,
    adminData: {
      firstName: '',
      lastName: '',
      level: 0,
      phoneNumber: '',
      jobPosition: '',
      email: '',
      password:''
    },
    isCreateAdmin: false,
    isEditAdmin: false,
    disableBtn: false,
    admins: []
    
  }

  componentWillMount(){
    this.getAdminData()
    this.getAdmins()
    const adminData =  JSON.parse(localStorage.getItem("userData" + API_URL));
    if(adminData.level < 3) return window.location.href = "/";
  }

  getAdminData = async () => {
    try {
       
    } catch (error) {
         
    }
   
 }

 getAdmins = async () => {
  try {
    const response = await axios.get(API_URL + '/admin/admins', config);
    if(response.data.message == "success") {
      this.setState({admins: response.data.admins})
      console.log(response.data.admins)
     // alert("SUCCESS!!")
    }
  } catch (error) {
    console.log(error)
    alert("Errors creating admin")
  }
 
}

showEditAdmin = (adminData) => {
   this.setState({isEditAdmin: true, isCreateAdmin: true, adminData}, () => {
   })
}

editAdminFunc = async () => {
  try {
    const data = this.state.adminData;
    //console.log("edit data", data)
    this.setState({disableBtn: true})
    const response = await axios.post(API_URL + '/admin/edit', data, config);
    if(response.data.message == "success") {
      this.setState({disableBtn: false})
      console.log(response.data)
      window.location.reload()
    }
  } catch (error) {
       console.log(error)
       this.setState({disableBtn: false})
       alert("Errors editing admin")
  }
 
}

resetAdminFunc = async () => {
  try {
    const data = this.state.adminData;
   // console.log("reset", data)
    this.setState({disableBtn: true})
    const response = await axios.post(API_URL + '/admin/reset', {_id: data._id}, config);
    if(response.data.message == "success") {
      this.setState({disableBtn: false})
      console.log(response.data)
      window.location.reload()
    }
  } catch (error) {
       console.log(error)
       this.setState({disableBtn: false})
       alert("Errors reseting admin")
  }
 
}

createNewAdminFunc = async () => {
  try {
    const data = this.state.adminData;
    this.setState({disableBtn: true})
    const response = await axios.post(API_URL + '/admin/create', data, config);
    if(response.data.message == "success") {
      this.setState({disableBtn: false, adminData: {}})
      console.log(response.data)
      alert("SUCCESS!!")
    }
  } catch (error) {
       console.log(error)
       this.setState({disableBtn: false})
       alert("Errors creating admin")
  }
 
}

onChange = (e) => {
    this.setState({ ...this.state, adminData: {...this.state.adminData, [e.target.name]: e.target.value } })
}

  render() {
    const {firstName, lastName, level, phoneNumber, jobPosition, email, password} = this.state.adminData;
    const {loading, isCreateAdmin, isEditAdmin} = this.state;
        return (
            <div className="main-content-wrapper">
                  <div style={{display: "flex", alignItems: 'center', justifyContent: "space-between"}}>
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Admin Management</p>
                    {!isCreateAdmin && <div onClick={() => this.setState({isCreateAdmin: true})} className="admin-create-btn"><p>Add New Admin</p> </div>}
                  </div>
                  { !isCreateAdmin ?
                    <div className="admin-management-wrapper">
                    <div className='admin-list-head'>
                        <li className="">
                             <p>Full Name</p>
                        </li>
                        <li className="">
                             <p>Level</p>
                        </li>
                        <li className="">
                             <p>status</p>
                        </li>
                        <li className="">
                             <p>Edit</p>
                        </li>
                    </div>
                        {this.state.admins.map(admin => (
                            <div key={admin._id} className='admin-list-body'>
                                <li className="">
                                    <p>{admin.firstName + ' ' + admin.lastName}</p>
                                </li>
                                <li className="">
                                    <p>{admin.level}</p>
                                </li>
                                <li className="">
                                    <p>{admin.status}</p>
                                </li>
                                <li onClick={() => this.showEditAdmin(admin)} className="admin-edit-btn">
                                    <p>Edit</p>
                                </li>
                          </div>
                        ))}
                    </div>
                    :
                    <div style={{display:'flex', flexDirection:'column', width:"100%", alignItems:'center', marginTop: 50}}>
                      <div onClick={() => this.setState({isCreateAdmin: false, isEditAdmin: false, adminData: {}})} className="admin-create-btn" style={{width: 250, textAlign:'center', marginBottom: 20}}>
                              <p>CLOSE</p>
                        </div>
                        <p style={{padding: 10, background:"rgb(252, 239, 223)", width:"48.5%"}}>Create New Admin</p>
                        <div className='admin-inputs-wrap' style={{display: 'flex', flexDirection: 'column',  width:"50%"}}>
                            <input type='text' onChange={(e) => this.onChange(e)} name="firstName" placeholder='Enter First Name' value={firstName} />
                            <input type='text' onChange={(e) => this.onChange(e)} name="lastName" placeholder='Enter last Name' value={lastName} />
                            <input disabled={isEditAdmin} type='password' onChange={(e) => this.onChange(e)} name="password" placeholder='Enter Password' value={password} />
                            <input type='number' onChange={(e) => this.onChange(e)} name="level" placeholder='Enter level' value={level} />
                            <input disabled={isEditAdmin}   type='text' onChange={(e) => this.onChange(e)} name="email" placeholder='Enter Email' value={email} />
                            <input type='text' onChange={(e) => this.onChange(e)} name="phoneNumber" placeholder='Enter Phone Number' value={phoneNumber} />
                            <input type='text' onChange={(e) => this.onChange(e)} name="jobPosition" placeholder='Enter Job Position' value={jobPosition} />
                        </div>
                        { isEditAdmin? 
                          <div>
                              <div 
                                  onClick={this.editAdminFunc}
                                  className="admin-create-btn" style={{width: 250, textAlign:'center'}}>
                                    <p>Edit Admin</p>
                              </div>
                              <div 
                                  onClick={this.resetAdminFunc}
                                  className="admin-create-btn" 
                                    style={{width: 100, textAlign:'center', background:"red", marginTop: 20}}>
                                    <p>Reset Admin</p>
                              </div>
                            </div>
                          :
                          <div 
                              onClick={this.createNewAdminFunc}
                              className="admin-create-btn" style={{width: 250, textAlign:'center'}}>
                                <p>Create New Admin</p>
                          </div>
                        }
                   </div>
                  }
                    
                
            </div>
          )
    }
}
