import React, {Component} from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Header from './components/Header/Header';
import SideBar from './components/SideBar/SideBar';
import Dashboard from './components/Dashboard/Dashboard';
import UserDetail from './components/UserDetail/UserDetail';
import UserDetailRejected from './components/UserDetail/UserDetailRejected';
import Transactions from './components/Transactions/Transactions';
import TransactionDetail from './components/TransactionDetail/TransactionDetail';
import Login from './components/Login/Login'
import Verifications from './components/Verifications/Verifications'
import ApprovedAccounts from './components/ApprovedAccounts/ApprovedAccounts';
import VerificationDetail from './components/VerificationDetail/VerificationDetail';
import ApproveDetail from './components/ApproveDetail/ApproveDetail';
import Analytics from './components/Analytics/Analytics';
import Refferals from './components/Refferals/Refferals';
import Savings from './components/Savings/Savings';
import Loans from './components/Loans/Loans';
import EmailData from './components/EmailData/EmailData';
import AdminManagement from './components/AdminManagement/AdminManagement';


import './App.css';
import {API_URL, config} from './config/baseUrl';


class App extends Component {
  
   state = {
     isLoggedIn: true
   }

  
  render(){
     console.log("isloggedIn?", localStorage.getItem('loggedIn'));
     return (
      localStorage.getItem('loggedIn') == "yes" && localStorage.getItem('deviceID' + API_URL) ? 
      <Router>
        <div className="page-container">

            <Header />

            <div className="content-wrapper">
              
                <SideBar />

                <Route path="/" exact component={Dashboard} />
                <Route path="/login" exact component={Login} />
                <Route path="/user/:id" exact component={UserDetail} />
                <Route path="/rejected/:id" exact component={UserDetailRejected} />
                <Route path="/transactions" exact component={Transactions} />
                <Route path="/transactions-detail/:id" exact component={TransactionDetail} />
                <Route path="/verifications" exact component={Verifications} />
                <Route path="/approved" exact component={ApprovedAccounts} />
                <Route path="/verification/:id" exact component={VerificationDetail} />
                <Route path="/verify/:id" exact component={ApproveDetail} />
                <Route path="/analytics" exact component={Analytics} />
                <Route path="/Refferals" exact component={Refferals} />
                <Route path="/Savings" exact component={Savings} />
                <Route path="/loans" exact component={Loans} />
                <Route path="/email-datas" exact component={EmailData} />
                <Route path="/admin" exact component={AdminManagement} />

          </div>
        </div>
      </Router>
      :
      <Login />
    );
  }
}

export default App;
