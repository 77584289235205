import React, {Component} from 'react';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import {API_URL, config} from '../../config/baseUrl';
import axios from 'axios';

import './Login.css';

export default class Login extends Component {

  state = {
    email: '',
    password: '',
    loading: false,
    otp: '',
    serverOtp: null,
    showOtpInput: false,
    isloading: false,
    token: '',
    deviceID: '',
    otpRef: '',
    isNewPassword: false,
    newPassword: ''
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
     //.log(e.target.name, e.target.value)
   }

   checkLoginValid = async () => {
      try {
        const res = await axios.post(API_URL + '/auth/login', {
          email: this.state.email,
          deviceID: localStorage.getItem('deviceID' + API_URL),
          password: this.state.password,
          isVerifyLogin: true,
             }, config
        )
        console.log(res.data)
        if(res.data.message == "success") return true;
      } catch (error) {
         console.log(error)
      }
   }

  sendOTP = async (e) => {
    e.preventDefault();
    this.setState({loading: true})
    //console.log("deviceID: config.headers.deviceid,", config.headers.deviceid, localStorage.getItem('deviceID' + API_URL))
     const isValid = await this.checkLoginValid();
    if(!isValid) {
      this.setState({loading: false})
      return alert("Invalid login details!, check email and password and try again.")
    }else {
      axios.post(API_URL + '/auth/otp', {
        email: this.state.email,
        deviceID: config.headers.deviceid
       }, config
     ).then(response => {
          if (response.data.message === "success") {
              this.setState({
                showOtpInput: true, 
                loading: false, 
                otpRef: response.data.otpRef,
              })
          }
    
          else {
            alert(response.data.message)
            console.log(response.data.message)
          }
        })
        .catch(error => {
          this.setState({
            loading: false, 
          })
          //alert("network error")
          console.log(error)
        })
    }
    
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true})
   // console.log("deviceID: config.headers.deviceid,", config.headers.deviceid)
    axios.post(API_URL + '/auth/login', {
      email: this.state.email,
      deviceID: localStorage.getItem('deviceID' + API_URL),
      password: this.state.password,
      otpRef: this.state.otpRef,
      otp: this.state.otp,
      newPassword: this.state.isNewPassword? this.state.newPassword : null
     }, config
    )
    .then(response => {
      if (response.data.message === "success") {
          //.log(response.data.userData)
          this.setState({
            showOtpInput: false, 
            deviceID: response.data.deviceID,
            token: response.data.token,
            email: ''
          }, () => {
              localStorage.setItem('userData' + API_URL, JSON.stringify(response.data.adminData));
              localStorage.setItem('token' + API_URL, response.data.token);
              if(response.data.deviceID )localStorage.setItem('deviceID' + API_URL, response.data.deviceID);
            //  console.log(localStorage.getItem('deviceID'), response.data.token)
              localStorage.setItem('loggedIn', "yes");
              window.location.reload();
          })
      }else if(response.data.message === "Create New Password")
             //alert(response.data.message)
             this.setState({isNewPassword: true, loading: false})

      else {
        alert("login access error")
        console.log(response.data.message)
      }
    })
    .catch(error => {
      //alert("network error")
      console.log(error)
      this.setState({
        loading: false, 
      })
    })
  }

  LoginFunc = (e) => {
    e.preventDefault();
    if (this.state.otp == this.state.serverOtp) {
        //.log(this.state.userData)
        localStorage.setItem('userData' + API_URL, JSON.stringify(this.state.userData));
        localStorage.setItem('token' + API_URL, this.state.userData.token);
        localStorage.setItem('deviceID' + API_URL, this.state.userData.deviceID);
        localStorage.setItem('loggedIn', "yes");
         window.location.reload();
    }

    else {
      alert("enter correct otp")
    }
  }

  resendOtp = () => {
    this.setState({showOtpInput: false})
  }

    render() {
        return (
               <div className="login-page-container">
                 
                  <div className="logo-div">
                    <img className="logo-image" src="./dodo-blue.png" />
                  </div>
 
                   {this.state.showOtpInput ? 
                     <form onSubmit={this.LoginFunc} style={{paddingBottom:35}}
                     className="login-form-wrapper">
                      {this.state.isNewPassword ?
                          <input onChange={this.onChange} type="Password" placeholder="Enter New Password"  name="newPassword" required value={this.state.newPassword}/>
                          :
                          <input onChange={this.onChange} placeholder="OTP" type="text" name="otp" required value={this.state.otp}/>
                      }
                     {this.state.loading ? 
                        <button style={{background: '#7296F1'}}  disabled={true}>
                          <Spinner color="#fff"/>
                        </button> 
                        :
                        <button style={{background: '#1352F3'}}  onClick={this.onSubmit}>SUBMIT</button>
                     }
                     <p onClick={this.resendOtp}
                        style={{fontWeight: 500, cursor: 'pointer'}} >resend otp</p>
                     </form>
                     :
                     <form onSubmit={this.sendOTP} style={{paddingBottom:35}}
                      className="login-form-wrapper">
                      <input style={{marginTop: 30}}
                         onChange={this.onChange} placeholder="Email" type="Email" name="email" required/>
                      <input onChange={this.onChange} placeholder="Password" type="Password" name="password"  required />

                       {this.state.loading ? 
                       <button style={{border: 'none',background: '#1352F3'}} disabled={true}>
                          <Spinner color="#fff"/>
                       </button> 
                       :
                       <button style={{background: '#1352F3'}}  type="submit">LOGIN</button>
                      }
                    </form>
                   }

               </div>
        )
    }

}
