import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login';
import {API_URL, config} from '../../config/baseUrl';
import CustomSelect from '../CustomSelect/CustomSelect';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

import './Savings.css';


var REFERENCE = moment();
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
const adminData =  JSON.parse(localStorage.getItem("userData" + API_URL));
export default class Savings extends Component {

state = {
    loading: true,
    allUsersList: [],
    savings: [],
    queryString: '',
    isLoggedIn: true,
    usersCount: 0,
    transactionsCount: 0,
    verifiedUserCount: 0,
    searchLoading: false,
    isOpen: false,
    loadLimit: 100,
    isSuccess: false,
    isLoadingEvent: false,
    isDialogOpen: false,
    savingData: {accountNumber: '000000000000000000', amount: 4500},

}

componentWillMount(){   
    this.getSavings();
}


getSavings = async () => {
    try {
        const response = await axios.get(API_URL + '/user/saving/0/500', config);
        if(response.data.message == "success"){
           // console.log("savings", response.data.savings)
            this.setState({
                savings: response.data.savings, 
                loading:false, 
            })
        }else this.setState({loading:false})
    } catch (error) {
        this.setState({loading:false})
        console.log('error ' + error);
    }
    
}






logOut = () => {
    //localStorage.clear();
   // window.location.reload();
}

    

isOpenFunc = () => {
    this.setState({isOpen: !this.state.isOpen})
}

checkIfSavingsStatus = (status) => {
    if(status == "Closing") return true;
    else return false;
}


//COMPUTE EARNINGS PER DAY FOR ALL SAVINGS
calculateEarnings = (savingData) => {
  if(savingData.status == "CLOSING" || savingData.status == "CLOSED") return savingData.earnings.toFixed(2);
  const diffDays = moment().diff(moment(savingData.startDate), 'days');
  const dailyInterest = savingData.apy / 365;
  const earnings = (dailyInterest / 100) * savingData.amount * diffDays;
  return earnings.toFixed(2);
}

daysCount = (savingData) => {
  if(savingData.status == "CLOSED" || savingData.status == "CLOSING"){
    const diffDays = moment(savingData.closedDate).diff(moment(savingData.startDate), 'days');
    return parseInt(diffDays)
  }else {
    const diffDays = moment().diff(moment(savingData.startDate), 'days');
    return parseInt(diffDays)
  }
}

openDialog = (savingData) => {
    if(adminData.level < 3) return alert("You need Level 3 access for this feature")
    this.setState({ savingData, isDialogOpen: true})
}

handleClose = () => this.setState({ isDialogOpen: false })

//PROCESS CLOSING SAVINGS
processSavingsWithdraw = async (savingData) => {
        try {
            if(adminData.level < 3) return null;
            const bodyParameters = {
                savingsID: savingData._id,
            }  
            const response = await axios.post(API_URL + '/user/saving/process', bodyParameters, config);
            if(response.data.message == "success"){
                console.log("savings", response.data)
                this.setState({isSuccess: true, isLoadingEvent: false});
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }else {
                    this.setState({isSuccess: false, isLoadingEvent: false})
                    console.log("processSavingsWithdraw", response.data)
                    alert(response.data.message);
            }
        } catch (error) {
            console.log("processSavingsWithdraw Error", error)
        }
    }




render() {
        const {loading, isDialogOpen, isSuccess, isLoadingEvent, savingData} = this.state;
        const closingSavings = this.state.savings.sort(function(y, x){
                return new Date(x.startDate) - new Date(y.startDate);
            }).map(saving => {
            return (
                    saving.status == "CLOSING" &&
                    <ul key={saving._id} className="list-contents">
                        <li className="list-items">
                            {saving.accountNumber}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                            <CurrencyFormat value={saving.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                              prefix={saving.currency} />
                        </li>
                        <li className="list-items" style={{paddingLeft:30}}>
                            <span style={{
                                backgroundColor: '#ddf2ff',
                                color: '#08227b',
                                padding: 10,
                                fontWeight: 600,
                                borderRadius: 5
                                }}>
                                {saving.status}
                            </span>
                        </li>
                        <li className="list-items">
                            <CurrencyFormat value={this.calculateEarnings(saving)} displayType={'text'} thousandSeparator={true} 
                              prefix={saving.currency} />
                        </li>
                        <li className="list-items" 
                            style={{fontWeight:'bold', color: saving.plan == "diamond" ? "#982AC0": saving.plan == "gold"? "#DE9415" : "#373737"}}>
                            {saving.plan.toUpperCase() + " " + saving.term}
                        </li>
                        <li className="list-items" style={{width: 150}}>
                            {this.daysCount(saving)}
                        </li>
                        <li className="list-items">
                              <Link style={{}}>
                                <button 
                                  onClick={() => this.openDialog(saving)}
                                  className="savings-confirm-button">
                                    PROCESS
                                </button>
                            </Link>
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80, 
                             color:'#777', paddingLeft:10}}>
                            {moment(saving.startDate).format('DD MMM YYYY')} @ 
                            {moment(saving.startDate).format('hh:mm a')}
                        </li>
                        
                   </ul>
              )
          })

                const savingLocked = this.state.savings.sort(function(y, x){
                    return new Date(x.startDate) - new Date(y.startDate);
                    }).map(saving => {
                    return (
                        saving.status == "LOCKED" &&
                        <ul key={saving._id} className="list-contents">
                            <li className="list-items">
                                {saving.accountNumber}
                            </li>
                            <li className="list-items" style={{paddingLeft:40}}>
                            <CurrencyFormat value={saving.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                              prefix={saving.currency} />
                            </li>
                            <li className="list-items" style={{paddingLeft:30, }}>
                                <span style={{
                                    backgroundColor: 
                                        saving.status == "OPEN" ? "#daf7e1" : saving.status == "CLOSED" ? "#D49DDF" : "#F7F7D6",
                                    color: '#08227b',
                                    padding: 10,
                                    fontWeight: 600,
                                    borderRadius: 5
                                    }}>
                                    {saving.status}
                                </span>
                            </li>
                            <li className="list-items">
                                <CurrencyFormat value={this.calculateEarnings(saving)} displayType={'text'} thousandSeparator={true} 
                                prefix={saving.currency} />
                            </li>
                            <li className="list-items" 
                                style={{fontWeight:'bold', color: saving.plan == "diamond" ? "#73249E": saving.plan == "gold"? "#DE9415" : "#373737"}}>
                                {saving.plan.toUpperCase() + " " + saving.term}
                            </li>
                            <li className="list-items" style={{width: 150}}>
                                {this.daysCount(saving)}
                            </li>
                            <li className="list-items">
                                <button style={{opacity: 0.1, cursor:'none'}}
                                    className="savings-confirm-button">
                                    CONFIRM
                                </button>
                            </li>
                            <li className="list-items-title" style={{fontSize: 9, width: 80, 
                                color:'#777', paddingLeft:10}}>
                                {moment(saving.startDate).format('DD MMM YYYY')} @ 
                                {moment(saving.startDate).format('hh:mm a')}
                            </li>
                            
                    </ul>
                )
            })  




          const savingOpen = this.state.savings.sort(function(y, x){
                 return new Date(x.startDate) - new Date(y.startDate);
                }).map(saving => {
                  return (
                    saving.status == "OPEN" &&
                    <ul key={saving._id} className="list-contents">
                        <li className="list-items">
                            {saving.accountNumber}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                        <CurrencyFormat value={saving.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} 
                              prefix={saving.currency} />
                        </li>
                        <li className="list-items" style={{paddingLeft:30, }}>
                            <span style={{
                                backgroundColor: 
                                    saving.status == "OPEN" ? "#daf7e1" : saving.status == "CLOSED" ? "#D49DDF" : "#F7F7D6",
                                color: '#08227b',
                                padding: 10,
                                fontWeight: 600,
                                borderRadius: 5
                                }}>
                                {saving.status}
                            </span>
                        </li>
                        <li className="list-items">
                              <CurrencyFormat value={this.calculateEarnings(saving)} displayType={'text'} thousandSeparator={true} 
                                prefix={saving.currency} />
                        </li>
                        <li className="list-items" 
                            style={{fontWeight:'bold', color: saving.plan == "diamond" ? "#982AC0": saving.plan == "gold"? "#DE9415" : "#373737"}}>
                            {saving.plan.toUpperCase() + " " + saving.term}
                        </li>
                        <li className="list-items" style={{width: 150}}>
                            {this.daysCount(saving)}
                        </li>
                        <li className="list-items">
                            <button style={{opacity: 0.1, cursor:'none'}}
                                className="savings-confirm-button">
                                CONFIRM
                            </button>
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80, 
                             color:'#777', paddingLeft:10}}>
                            {moment(saving.startDate).format('DD MMM YYYY')} @ 
                            {moment(saving.startDate).format('hh:mm a')}
                        </li>
                        
                   </ul>
              )
          })  

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#1374ec" size={30}/> 
                  <p style={{color: '#1a2d6e', fontSize: 12, marginLeft: -17, marginTop: 12}}>
                      {this.state.loadLimit > 100 ? "Sorting datas..." : ''}</p> 
              </div>          
             :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Savings</p>
                </div> 
                    
                <div className="row-title" >
                    <p style={{fontWeight:500, color:'#101177'}}>All Savings</p>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Account No.
                        </li>
                        <li className="list-items-title" style={{paddingLeft:40}}>
                            Principal
                        </li>
                        <li className="list-items-title" style={{paddingLeft:30}}>
                            Status
                        </li>
                        <li className="list-items-title">
                            Earnings
                        </li>
                        <li className="list-items-title">
                           plan (term)
                        </li>
                        <li className="list-items-title">
                            Days Count
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                        <li className="list-items-title" style={{width: 80}}>
                            Time
                        </li>
                       
                    </ul>
                     
                     {this.state.loading ?
                        <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                          <Spinner color="#1374ec" size={20}/>   
                        </div> 
                        :
                        <div>
                            {closingSavings}
                            {savingOpen}
                            {savingLocked}
                        </div>
                     }
                    
                </div>

                {isDialogOpen &&
                  <div className="dialog-box-container">
                     <div className="dialog-box-header">
                       <h3>{"SUPER SAVER WITHDRAWAL"}</h3>   
                        <img src="/close-icon.png" onClick={this.handleClose} />
                      </div>                             
                        {isLoadingEvent ? 
                            <div style={{display: "flex", marginTop:80}}>
                              <Spinner color="#47c494" size={20}/>   
                            </div> 
                            :
                        isSuccess ? 
                        <p style={{fontSize: 13, color: 'blue', width: "80%", marginTop:90, textAlign: 'center'}}>
                            {"SUCCESSFULLY PROCESSED"}
                        </p>
                        :
                        <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:100}}>
                            You are about to confirm withdrawal for account No 
                            <em style={{fontWeight: 'bold', fontSize: 15, color: 'blueviolet'}}>{" " + savingData.accountNumber + " "}</em>.
                            Amount: <span style={{fontWeight: 'bold', fontSize: 20, color: '#6ad291'}}>{" #" + savingData.amount.toFixed(2) + " "}</span> .
                        </p>
                        }
                        <ButtonCustom 
                            text={"PROCEED"}
                            bgColor= "red"
                            opacity={0.5}
                            color="white"
                            onClick={() => this.processSavingsWithdraw(savingData)}
                            marginTop={60}
                        />
                </div>

               }

            </div>
               
        )
    }

}
