import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import CurrencyFormat from 'react-currency-format';
import {API_URL, config, IMAGE_BASE} from '../../config/baseUrl';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import { countries } from '../../config/countries';
import Toggle from 'react-toggle'
import './UserDetail.css';

const options = [
   { value: 'identity', label: 'Identification does not meet criteria' },
   { value: 'incomplete', label: 'User data is incomplete' },
   { value: 'address', label: 'A valid address is required' },
   { value: 'selfie', label: 'Selfie Image quality is poor' },
   { value: 'block', label: 'Document is not Authentic' },
 ];


export default class UserDetailRejected extends Component {
 
   state = {
      userData: {},
      userDataRejected: {},
      loading: true,
      isDialogOpen: false,
      isDialogOpenVerify: false,
      dialogKey: '',
      rejection_text: '',
      colorBG: '',
      amount: 0,
      selectedOption: null,
      isRejection: false,
      isApproval: false,
      isSuccess: false,
      isLoadingEvent: false,
      isBlock: false,
      isUnblock: false
   }

componentDidMount(){
       //.log(this.props.match.params.id)
        axios.get(API_URL + '/user/data/' + this.props.match.params.id, config)
        .then(response => {
            if (response.data.message === "success") {
               console.log("UserData", response.data);
               this.setState({userData: response.data.userData, loading:false, userDataRejected: response.data.userDataRejected})
            }else {
                alert("Alert getting UserData Error1")
                console.log("UserData error", response.data);
            }
        })
        .catch((error) => {
            console.log('error ' + error);
        });
 }

//UPDATE USER ACCOUNT STATUS
updateUserAccount = async (status) => {
   var bodyParameters = {
         userID: this.props.match.params.id,
         status,
   }

   try {
      const response = await axios.post(API_URL + `/user/update`, bodyParameters, config);
      if (response.data.message === "success") {
            console.log(status, response.data, bodyParameters.text)
            this.setState({isSuccess: true, isLoadingEvent: false, rejection_text: ''});
            setTimeout(() => {
               window.location.reload()
            }, 1000);
      }else {
          this.setState({isSuccess: false, isLoadingEvent: false});
          alert("update failed to process" + response.data.message )
      }
   } catch (error) {
       console.log("Network error", error);
       this.setState({isSuccess: false, isLoadingEvent: false});
       alert("Network error!")
   }
  
}

//UPDATE USER ACCOUNT STATUS
processVerification = async (dialogKey) => {
      if(dialogKey == "reject" && this.state.selectedOption ==  null) return alert("Select a reason for rejection")
      this.setState({isLoadingEvent:  true})
      const {amount} = this.state;
      const text = dialogKey == "reject" ? this.state.selectedOption.label : '';
      
      const bodyParameters = {
         userID: this.props.match.params.id,
         reason: text + '.' + this.state.rejection_text,
         type: dialogKey == "reject"? this.state.selectedOption.value :  dialogKey,
      }

      try {
         const response = await axios.post(API_URL + `/user/verification/${dialogKey}`, bodyParameters, config);
         if (response.data.message === "success") {
               console.log(dialogKey, response.data, bodyParameters.text)
               this.setState({isSuccess: true, isLoadingEvent: false, rejection_text: ''});
               setTimeout(() => {
                  window.history.go(-1)
               }, 1000);
         }else {
            this.setState({isSuccess: false, isLoadingEvent: false});
            alert("Request failed to process" + response.data.message )
         }
      } catch (error) {
          console.log("Network error", error);
          this.setState({isSuccess: false, isLoadingEvent: false});
          alert("Network error!")
      }
     
   }
  

   openDialogVerify = (isRejection, isApproval) => {
      this.setState({ isDialogOpenVerify: true, isRejection, isApproval })
   }


   onChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
       ////.log(e.target.name, e.target.value)
    }

   openDialog = (isBlock) => {
      this.setState({ isDialogOpen: true, isBlock})
   }
 
  handleClose = () => this.setState({ isDialogOpen: false, isDialogOpenVerify: false })

  getUserCountryData = (telCode) => {
     const countryData =  countries.find(data => data.telCode == telCode);
     //console.log(countryData)
     return countryData;
  }

  checkAminLevel = (level, access) => {
   const {userData} = this.state;
    if((level >= access) && userData.accountUpgradeStatus == "processing" || userData.accountUpgradeStatus == "approved") return true;
}
handleChange = (selectedOption) => {
   this.setState({ selectedOption });
 };

 trimPhotoBlob = (blob) => {
   const index = blob.indexOf('"');
   return index >= 1? blob.substring(0, index) : blob;
 };

    render() {
        const {userData, isDialogOpenVerify, loading, isDialogOpen, colorBG, isRejection, isApproval, isSuccess, isLoadingEvent, isBlock, userDataRejected} = this.state;
        const blockStatus =  userData.status == "Active" ? "Block" : "Unblock";
        //console.log({blockStatus}, userData.status)
        const suspendStatus =  userData.status == "Blocked" ? "Unsuspend" : "Suspend";
        const adminData =  JSON.parse(localStorage.getItem("userData" + API_URL));

        return (
            loading ? 
            <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
               <Spinner color="#47c494" size={30}/>   
            </div>          
            :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>User details</p>
                     <div style={{display: 'flex'}}>
                        {this.checkAminLevel(adminData.level, 1) &&
                              <div className="call-to-actions-wrapper">  
                                  { userData.accountUpgradeStatus == "processing" &&
                                     <ButtonCustom 
                                       text="Verify"
                                       bgColor="blue"
                                       opacity={0.8}
                                       onClick={() => this.openDialogVerify(false, false)}
                                       width={130}
                                    /> 
                                  }
                                    { userData.accountUpgradeStatus == "approved" ?
                                         adminData.level >= 3 &&
                                          <ButtonCustom 
                                          text="Approve"
                                          bgColor="green"
                                          opacity={0.8}
                                          onClick={() => this.openDialogVerify(false, true)}
                                          width={130}
                                       />: '' 

                                    }   
                                    <ButtonCustom 
                                       text="Reject"
                                       bgColor= "red"
                                       opacity={0.8}
                                       onClick={() => this.openDialogVerify(true, false)}
                                       width={130}
                                    />
                              </div>
                           }
                          <div className="call-to-actions-wrapper">
                              {userData.status !== "Active" ?
                                    <ButtonCustom 
                                    text="Unblock"
                                    bgColor= "#f90732"
                                    opacity={0.4}
                                    onClick={() => this.openDialog(true)}
                                    width={120}
                                    /> :
                              <ButtonCustom 
                                    text="Block"
                                    bgColor= "#f90732"
                                    opacity={0.7}
                                    onClick={() => this.openDialog(true)}
                                    width={120}
                                    />
                              }
                        </div>
                    </div>
                   
                </div>

                <div className="details-container">


                {isDialogOpenVerify ?
                     isRejection ?
                     <div className="dialog-box-container" style={{height:  isRejection? 480 : 300, marginTop: -100}}>
                        <div className="dialog-box-header">
                          <h3>REJECT VERIFICATION</h3>  
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>           
                        <p style={{fontSize: 12, marginTop: 5, color: 'rgb(26, 26, 102)'}}>Select below the reason for rejection</p>
                        <Select
                           value={this.state.selectedOption}
                           onChange={this.handleChange}
                           options={options}
                           />
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:30}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'red', width: "80%", marginTop:40, textAlign: 'center'}}>
                              SUCCESSFULLY REJECTED!
                            </p>
                            :
                         <p style={{fontSize: 13, marginTop: 5, color: '#333', width: "80%"}}>
                           The user will be notified upon confirmation. Carefully check that user ID and informations fullfills verification requirements</p>
                           }
                           <p style={{fontWeight: 600, marginTop: 10}}>Give precise reason why upgrade is rejected ? </p>
                            <textarea className="other-reason-input" 
                              rows="4"
                              onChange={this.onChange}
                              value={this.state.rejection_text}
                              name="rejection_text"
                            />

                           <ButtonCustom 
                              text="CONFIRM REJECTION"
                              bgColor= "red"
                              opacity={1}
                              color="white"
                              onClick={() => this.processVerification("reject")}
                              marginTop={40}
                           />
                      </div> : 
                       isApproval ? 
                       <div className="dialog-box-container">
                         
                        <div className="dialog-box-header">
                          <h3>APPROVE VERIFICATION</h3>   
                          <img src="/close-icon.png" onClick={this.handleClose} />
                          
                        </div>
                            
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'green', width: "80%", marginTop:80, textAlign: 'center'}}>
                              SUCCESSFULLY APPROVED!
                            </p>
                            :
                            <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:80}}>
                              The user will be notified upon confirmation. Carefully check that user ID and informations fullfills verification requirements
                           </p>
                           }
                           <ButtonCustom 
                              text="CONFIRM APPROVAL"
                              bgColor= "green"
                              opacity={1}
                              color="white3"
                              onClick={() => this.processVerification("approve")}
                              marginTop={50}
                           />
                      </div>
                       : 
                       <div className="dialog-box-container">
                        <div className="dialog-box-header">
                          <h3>ACCEPT & CONFIRM VERIFICATION</h3>   
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>                             
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'green', width: "80%", marginTop:80, textAlign: 'center'}}>
                              SUCCESSFULLY VERIFIED THE ACCOUNT!
                            </p>
                            :
                            <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:80}}>
                              The user will be notified upon confirmation. Carefully check that user ID and informations fullfills verification requirements
                           </p>
                           }
                           <ButtonCustom 
                              text="CONFIRM VERIFICATION"
                              bgColor= "blue"
                              opacity={1}
                              color="white3"
                              onClick={() => this.processVerification("verify")}
                              marginTop={60}
                           />
                    </div>: ''

      
                   }
                  {isDialogOpen ?
                     isBlock ?
                     <div className="dialog-box-container">
                        <div className="dialog-box-header">
                          <h3>{userData.status !== "Active" ? "UNBLOCK ACCOUNT" : "BLOCK ACCOUNT"}</h3>  
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>           
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'red', width: "80%", marginTop:90, textAlign: 'center'}}>
                              {userData.status !== "Active" ? "SUCCESSFULLY UNBLOCKED!" : "SUCCESSFULLY BLOCKED!"}
                            </p>
                            :
                         <p style={{fontSize: 13, marginTop: 100, color: '#333', width: "80%"}}>
                           You are about to {userData.status == "Active" ? "block" : "unblock"} this user account No 
                             <em style={{fontWeight: 'bold', fontSize: 15, color: 'blueviolet'}}>{" " + userData.accountNumber + " "}</em>. 
                             Kindly make sure this action is justified.</p>
                           }
                           <ButtonCustom 
                              text="PROCCEED"
                              bgColor= "red"
                              opacity={1}
                              color="white"
                              onClick={() => this.updateUserAccount(userData.status == "Active" ? "Blocked" : "Active")}
                              marginTop={40}
                           />
                      </div>
                      :
                      <div className="dialog-box-container">
                        <div className="dialog-box-header">
                        <h3>{userData.status == "Blocked" ? "UNSUSPEND ACCOUNT" : "SUSPEND ACCOUNT"}</h3>   
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>                             
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'blue', width: "80%", marginTop:90, textAlign: 'center'}}>
                               {userData.status == "Blocked" ? "SUCCESSFULLY UNSUSPEND!" : "SUCCESSFULLY SUSPEND!"}
                            </p>
                            :
                            <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:100}}>
                              You are about to {userData.status == "Blocked" ? "unsuspend" : "suspend"} this user account No 
                              <em style={{fontWeight: 'bold', fontSize: 15, color: 'blueviolet'}}>{" " + userData.account_number + " "}</em>. Kindly make sure this action is justified.
                           </p>
                           }
                           <ButtonCustom 
                              text={"PROCEED"}
                              bgColor= "red"
                              opacity={0.5}
                              color="white"
                              onClick={() => this.updateUserAccount(suspendStatus)}
                              marginTop={60}

                           />
                    </div>: ''

                   }
                   <div className="user-details-wrapper">
                        <div className="user-data-info">
                           <p className="info-title">Fullname</p>
                           <p className="info-response">{!userData.firstName ? "Anonymouse" : `${userData.firstName} ${userData.middleName? userData.middleName : '' } ${userData.lastName}`}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account No.</p>
                           <p className="info-response">{userData.accountNumber}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Registered In</p>
                           <p className="info-response">{this.getUserCountryData(userData.countryCode).name}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Credit Score</p>
                           <p className="info-response">{userData.creditScorePoint}%</p>
                        </div>
                        { userData.loanFile &&
                           <div className="user-data-info">
                           <p className="info-title">Debt</p>
                           <p style={{fontWeight: 'bold', color:'red'}} className="info-response">
                           <CurrencyFormat value={userData.debt} displayType={'text'} thousandSeparator={true} prefix={'-N'} /></p>
                           
                        </div>}

                        <div className="user-data-info">
                           <p className="info-title">Email</p>
                           <p className="info-response">{userData.email}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Balance</p>
                           <div style={{color: '#4FA04C', fontWeight: '700'}}>
                           <p> <CurrencyFormat value={userData.balance['NGN'].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                           <p> <CurrencyFormat value={userData.balance['USD'].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'USD '} /></p>
                           </div>
                        </div>

                        <div className="user-data-info" >
                           <p className="info-title">Debt owe</p>
                           <p style={{color: '#E94242', fontWeight: '700'}}><CurrencyFormat value={-userData.debt.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} /></p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account status</p>
                           <p className="info-response">{userData.status}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account Tier</p>
                           <p className="info-response">Tier {userData.tier}</p>
                        </div>

                        { userData.migratedUser &&
                           <div className="user-data-info">
                              <p className="info-title">Is MigratedUser</p>
                              <p className="info-response">{userData.migratedUser}</p>
                           </div>
                        }

                        { userData.isAgent &&
                           <div className="user-data-info">
                              <p className="info-title">Is Agent</p>
                              <p style={{color: '#1565C1', fontWeight:'600'}} className="info-response">{'Bold Agent - YES'}</p>
                           </div>
                        }

                        <div className="user-data-info">
                           <p className="info-title">Account Upgrade Status</p>
                           <p className="info-response">{userData.accountUpgradeStatus}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">cashBack Balance</p>
                           <p> <CurrencyFormat value={userData.cashBackBalance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Reward Balance</p>
                           <p> <CurrencyFormat value={userData.rewards.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">claimedBonusAmount</p>
                           <p> <CurrencyFormat value={userData.claimedBonusAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Verification Status</p>
                           <p className="info-response">{userData.verificationStatus}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Verification ID Type/Number</p>
                            <div>
                              <p className="info-response">Type ID Submitted: { "   " + userDataRejected.verificationData.typeOfID}</p>
                              <p className="info-response">ID Number: { "   " + userDataRejected.verificationData.IDNumber}</p>
                              <p className="info-response">NIN: { "   " + userDataRejected.verificationData.NIN}</p>
                              <p className="info-response">Gender: { "   " + userDataRejected.verificationData.gender}</p>
                              <p className="info-response">phone No: { "   " + userData.verificationData.phone}</p>
                            </div>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Address</p>
                           {userDataRejected.address?
                             <div className="info-response">
                             <p className="info-title">{userDataRejected.address.street}</p>
                             <p className="info-title">{userDataRejected.address.city}</p><br></br>
                             <p className="info-title">{userDataRejected.address.city}</p>
                            </div>
                            :
                            <p className="info-title">-------</p>
                           }
                        </div>

                       { /*<div className="user-data-info">
                           <p className="info-title">Transactions count</p>
                           <p className="info-response">{" Transfers = " + userData.transfers.length} {"  Topups = " +  userData.topup.length}  {"  Withdrawals = " + userData.withdrawals.length} {"  Bills Payments = " + userData.billPayment.length}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">BVN</p>
                            {userData.BVN ? 
                             <p className="info-response">{'submitted'}</p>
                             :
                             <p className="info-response">{'Not submitted'}</p>
                            }
                         </div>*/
                       }

                        {userData.verificationOfficer &&
                          userData.verificationOfficer.rejectedBy &&
                           <div className="user-data-info">
                              <p className="info-title">Rejected By</p>
                              {userData.verificationOfficer.rejectedBy &&
                              <p style={{color:'#CA4063'}}
                                className="info-response">{userData.verificationOfficer.rejectedBy }</p>
                              }
                           </div>
                        }
                        
                        {userData.verificationOfficer &&
                           <div className="user-data-info">
                              <p className="info-title">Approved By</p>
                              {userData.verificationOfficer.approvedBy &&
                              <p style={{color:'#325739'}}
                                className="info-response">{userData.verificationOfficer.approvedBy }</p>
                              }
                           </div>
                        }

                        {userData.verificationOfficer &&
                           <div className="user-data-info">
                              <p className="info-title">Verified By</p>
                              {userData.verificationOfficer.verifiedBy &&
                              <p style={{color:'#274E79'}}
                                className="info-response">{userData.verificationOfficer.verifiedBy }</p>
                              }
                           </div>
                        }

                        <div className="user-data-info">
                           <p className="info-title">Date Of Birth</p>
                            {userData.isVerified &&
                             <p className="info-response">{userData.verificationData.dateOfBirth}</p>
                            }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Debits Today</p>
                           <CurrencyFormat value={userData.debitsToday} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Credits Today</p>
                           <CurrencyFormat value={userData.creditsToday} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Date Registered</p>
                           <p className="info-title">{moment(userData.registerationDate).format('DD MMM YYYY')}</p>
                        </div>

                        {/*<div className="user-data-info">
                           <p className="info-title">Bank accounts</p>
                           <div>
                            {userData.bank_accounts.map(bank => {
                               return (
                                 <div key={bank._id} className="info-response">
                                    <p className="info-title">{bank.bank_name}</p>
                                    <p className="info-title">{bank.bank_account_number}</p><br></br>
                                 </div>
                               )
                            })}
                            </div>
                        </div>
                  */
              }
                                 
                     </div>

                    <div>
                        <div className="user-identity-card-wrapper"
                              style={{width: 500, marginTop: -50}}>
                              <p style={{textAlign:"center", padding: 10, opacity: 0.4}}>Customer Selfie</p>
                              { userDataRejected.verificationData.selfieExt ? 
                                 <img style={{objectFit: 'contain', width:'90%'}}
                                    src={IMAGE_BASE + userDataRejected.verificationData.selfieExt} />
                                 :
                                 <img style={{objectFit: 'conver', opacity:0.3}}
                                    src="/user-id.jpg" />
                              }
                        </div>
                        <div className="user-identity-card-wrapper"
                             style={{width: 500, marginTop: -50}}>
                              <p style={{textAlign:"center", padding: 10, opacity: 0.4}}>ID Document Image</p>
                              { userDataRejected.verificationData.IDImageExt ? 
                                 <img style={{objectFit: 'contain', width:'90%'}}
                                    src={IMAGE_BASE + userDataRejected.verificationData.IDImageExt} />
                                 :
                                 <img style={{objectFit: 'conver', opacity:0.3}}
                                    src="/user-id.jpg" />
                              }
                        </div>
                        <div className="user-identity-card-wrapper"
                              style={{width: 500, marginTop: -50}}>
                              <p style={{textAlign:"center", padding: 10, opacity: 0.4}}>Customer BVN Photo</p>
                              { userData.verificationData.photoId ? 
                                 <img className="blob-to-image" src={"data:image/png;base64," + this.trimPhotoBlob(userData.verificationData.photoId)}></img>
                                 :
                                 <img style={{objectFit: 'conver', opacity:0.3}}
                                    src="/user-id.jpg" />
                              }
                        </div>
                    </div>
                </div>

                
            </div>
               
        )
    }

}
