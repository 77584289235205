import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login'
import {API_URL, config} from '../../config/baseUrl';
import { countries } from '../../config/countries';
import CurrencyFormat from 'react-currency-format';
import './Verifications.css';

export default class Verifications extends Component {

    state = {
       loading: true,
       allUsersList: [],
       usersList: [],
       queryString: '',
       isLoggedIn: true,
       usersCount: 0,
       transactionsCount: 0,
       verifiedUserCount: 0
    }

    componentWillMount(){      
        //GET ALL USERS DATAS
        this.getUserData();
    }

    getUserData = async () => {
        try {
            const response = await axios.get(API_URL + '/user/all/0/20?accountUpgradeStatus=processing', config)
           // console.log(response.data.users)
            this.setState({
                usersList: response.data.users, 
                allUsersList: response.data.users, 
                loading:false, 
                usersCount: response.data.users.length,          
            })
        } catch (error) {
            console.log("error getting user verififcations", error)
        }
           
    }

    searchUserList = async () => {
       const {queryString} = this.state;
        if (queryString != "") {
            const filteredList = []
            await this.state.allUsersList.map(user => {
            if (queryString == user.account_number || queryString == user._id) {
                filteredList.push(user)
             }
           })
          this.setState({usersList: filteredList})
        }

    }

    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({usersList: this.state.allUsersList});
        }
      }

      logOut = () => {
        // localStorage.clear();
        // window.location.reload();
      }

    getUserCountryData = (telCode) => {
     const countryData =  countries.find(data => data.telCode == telCode);
     //console.log(countryData)
     return countryData;
  }

    render() {
          
          const usersList = this.state.usersList.map(user => {
              return (
                    <ul key={user._id} className="list-contents">
                        <li className="list-items">
                            {user.firstName == null ? "Anonymouse" : user.firstName + " " + user.lastName}
                        </li>
                        <li className="list-items">
                            {user.boldAccountNumber}
                        </li>
                        <li className="list-items">
                            {user.status}
                        </li>
                        <li className="list-items">
                            {user.accountUpgradeStatus}
                        </li>
                        <li className="list-items">
                        <div>
                                <p> <CurrencyFormat value={user.balance['NGN'].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                                <p> <CurrencyFormat value={user.balance['USD'].toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'USD '} /></p>
                              </div>
                        </li>
                        <li className="list-items">
                        <Link to={"/user/" + user._id}>
                             <button className="trans-view-button">
                                View
                             </button>
                        </Link>
                        </li>
                   </ul>
              )
          }) 

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/>   
              </div>          
             :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Verification</p>
                </div>

                <div className="stat-boxes-wrap">
                   
                </div>   
                    
                <div className="row-title" >
                    <p style={{fontWeight:500}}>Users waiting Verifications</p>
                    
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Fullname
                        </li>
                        <li className="list-items-title">
                            Account number
                        </li>
                        <li className="list-items-title">
                            Status
                        </li>
                        <li className="list-items-title">
                            Verification status
                        </li>
                        <li className="list-items-title">
                            Balance
                        </li>
                        <li className="list-items-title">
                            View Details
                        </li>
                    </ul>
                     
                     {usersList}
                    
                </div>

            </div>
               
        )
    }

}
