import React, {Component} from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ButtonCustom from '../ButtonCustom/ButtonCustom';
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import CurrencyFormat from 'react-currency-format';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import {API_URL} from '../../config/baseUrl';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import { countries } from '../../config/countries';

import './VerificationDetail.css';

const options = [
   { value: 'identity', label: 'Identification does not met criteria' },
   { value: 'incomplete', label: 'User data is incomplete' },
   { value: 'image', label: 'Image quality is poor' },
 ];

export default class VerificationDetail extends Component {

   state = {
      userData: {},
      loading: true,
      isDialogOpen: false,
      dialogKey: '',
      colorBG: '',
      amount: 0,
      selectedOption: null,
      isRejection: false,
      isSuccess: false,
      isLoadingEvent: false,
      rejection_text: '',
      terminate: false,
      isStudentAccount: false
   }

   handleChange = (selectedOption) => {
      this.setState({ selectedOption })
    };

   handleToggleChange = () => {
      this.setState({isStudentAccount: !this.state.isStudentAccount })
    };

   componentDidMount(){
       //.log(this.props.match.params.id)
      const USER_TOKEN = localStorage.getItem('token');
        const AuthStr = 'Bearer '.concat(USER_TOKEN); 
        let config = {
         headers: { Authorization: AuthStr },
         params: {
           id: this.props.match.params.id
         },
       }
       
        axios.get(API_URL + '/admin/retrieve-user-details', config)
        .then(response => {
            if (response.data.message === "success") {
               //.log(response.data);
               this.setState({userData: response.data.userData, loading:false})
            }

            else if(response.data.message == "blocked") {
                this.logOut()
            }
        })
        .catch((error) => {
            //this.logOut();
            //.log('error ' + error);
        });
   }


   updateUserAccount = (dialogKey) => {
      if(dialogKey == "Reject" && this.state.selectedOption ==  null) return alert("Select a reason for rejection")
      this.setState({isLoadingEvent:  true})
      const {amount} = this.state;
      const USER_TOKEN = localStorage.getItem('token');
      var config = {
         headers: {'Authorization': "bearer " + USER_TOKEN}
      };

      const text = dialogKey == "Reject" ? this.state.selectedOption.label : '';
      
      var bodyParameters = {
         id: this.props.match.params.id,
         queryType: dialogKey,
         text,
         rejection_text: this.state.rejection_text,
         amount,
         isStudentAccount: this.state.isStudentAccount
      }
      
      axios.post(API_URL +  
         '/admin/update-user-account',
         bodyParameters,
         config
         ).then((response) => {
            if (response.data.message === "success") {
                ////.log(response.data);
               // window.location.reload();
               this.setState({isSuccess: true, isLoadingEvent: false, rejection_text: ''});
               setTimeout(() => {
                  window.location.href = "/verifications";
               }, 1000);
            }

            else if (response.data.message === "blocked") {
                this.logOut()
            }
            else this.setState({isSuccess: false, isLoadingEvent: false})
         }).catch((error) => {
            //.log(error)
            alert("network error");
            this.setState({isSuccess: false, isLoadingEvent: false})
      });
     
   }

   logOut = () => {
      localStorage.clear();
       window.location.reload();
    }


   onChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
       ////.log(e.target.name, e.target.value)
    }

   openDialog = (isRejection) => {
      this.setState({ isDialogOpen: true, isRejection, terminate: false })
   }

   terminateAccount = () => {
      this.setState({ isDialogOpen: true, isRejection: true, terminate: true })
   }

   checkAminLevel = (level) => {
      if(level != "Manager") return true;
   }

   handleClose = () => this.setState({ isDialogOpen: false, selectedOption: null, isStudentAccount: false })
  
   getUserCountryData = (telCode) => {
     const countryData =  countries.find(data => data.telCode == telCode);
     //console.log(countryData)
     return countryData;
  }

    render() {
        const {userData, dialogKey, loading, isDialogOpen, colorBG, isRejection, isSuccess, isLoadingEvent} = this.state;
        const adminData =  JSON.parse(localStorage.getItem("userData"));
        return (
            loading ? 
            <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
               <Spinner color="#47c494" size={30}/>   
            </div>          
            :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#272777', paddingLeft:10}}>Account verification</p>
                    
                    {this.checkAminLevel(adminData.level) &&
                     <div className="call-to-actions-wrapper">
                        <ButtonCustom 
                          text="Approve"
                          bgColor="green"
                          opacity={0.8}
                          onClick={() => this.openDialog(false)}
                          width={130}
                         />
                         <ButtonCustom 
                          text="Reject"
                          bgColor= "red"
                          opacity={0.8}
                          onClick={() => this.openDialog(true)}
                          width={130}
                         />

                         <ButtonCustom 
                           text="Terminate"
                           bgColor= "#1B1314"
                           opacity={0.7}
                           onClick={this.terminateAccount}
                           width={120}
                        />   
                    </div>
                  }
                </div>

                <div className="details-container" >

                   {isDialogOpen ?
                     isRejection ?
                     <div className="dialog-box-container" style={{height:  isRejection? 400 : 300}}>
                        <div className="dialog-box-header">
                          <h3>REJECT VERIFICATION</h3>  
                          <img src="/close-icon.png" onClick={this.handleClose} />
                        </div>           
                        <p style={{fontSize: 12, marginTop: 5, color: 'rgb(26, 26, 102)'}}>Select below the reason for rejection</p>
                        <Select
                           value={this.state.selectedOption}
                           onChange={this.handleChange}
                           options={options}
                           />
                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:30}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'red', width: "80%", marginTop:40, textAlign: 'center'}}>
                              SUCCESSFULLY REJECTED!
                            </p>
                            :
                         <p style={{fontSize: 13, marginTop: 5, color: '#333', width: "80%"}}>
                           The user will be notified upon confirmation. Carefully check that user ID and informations fullfills verification requirements</p>
                           }

                           <p style={{fontWeight: 600, marginTop: 10}}>Other reason for Rejection ? </p>
                            <textarea className="other-reason-input" 
                              onChange={this.onChange}
                              value={this.state.rejection_text}
                              name="rejection_text"
                            />

                           <ButtonCustom 
                              text={this.state.terminate ? "TERMINATE" : "CONFIRM REJECTION"}
                              bgColor= "red"
                              opacity={1}
                              color="white"
                              onClick={() => this.updateUserAccount(this.state.terminate ? "Terminate" : "Reject")}
                              marginTop={0}
                           />
                      </div>
                      :
                      <div className="dialog-box-container">
                         
                        <div className="dialog-box-header">
                          <h3>APPROVE VERIFICATION</h3>   
                          <img src="/close-icon.png" onClick={this.handleClose} />
                          
                        </div>
                            <h5 style={{margin: 0, padding: 0, marginTop:5, marginBottom: 5, color: "#3DA0DA"}}>Is This  A Student Account?</h5>  
                            <Toggle
                              defaultChecked={this.state.isStudentAccount}
                              onChange={this.handleToggleChange} 
                            /> 

                           {isLoadingEvent ? 
                              <div style={{display: "flex", marginTop:80}}>
                                <Spinner color="#47c494" size={20}/>   
                               </div> 
                              :
                            isSuccess ? 
                            <p style={{fontSize: 13, color: 'green', width: "80%", marginTop:80, textAlign: 'center'}}>
                              SUCCESSFULLY APPROVED!
                            </p>
                            :
                            <p style={{fontSize: 13, color: '#333', width: "80%", marginTop:80}}>
                              The user will be notified upon confirmation. Carefully check that user ID and informations fullfills verification requirements
                           </p>
                           }
                           <ButtonCustom 
                              text="CONFIRM APPROVAL"
                              bgColor= "green"
                              opacity={1}
                              color="white3"
                              onClick={() => this.updateUserAccount("Approve")}
                              marginTop={50}
                           />
                    </div>:''

                   }

                   <div className="user-details-wrapper">
                        <div className="user-data-info">
                           <p className="info-title">Fullname</p>
                           <p className="info-response">{userData == null ? "Anonymouse" : userData.fullname}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account No.</p>
                           <img style={{objectFit: 'conver', width:25, borderRadius: 2, marginRight: 5, opacity: 0.7}}
                              src={"/flags/" + this.getUserCountryData(userData.userCountryCode).code + ".jpg"} />
                           <p className="info-response">{userData.account_number}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Email</p>
                           <p className="info-response">{userData.email}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Balance</p>
                           <CurrencyFormat value={userData.balance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Account status</p>
                           <p className="info-response">{userData.blocked ? "Blocked" : "Active"}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Reward</p>
                            {userData.reward &&
                             <p className="info-response">{userData.reward}</p>
                            }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Verification Status</p>
                           <p className="info-response">{userData.verification_status}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Address</p>
                           {userData.address != undefined ?
                             <div className="info-response">
                             <p className="info-title">{userData.address.street}</p>
                             <p className="info-title">{userData.address.city}</p><br></br>
                             <p className="info-title">{userData.address.state}</p>
                            </div>
                            :
                            <p className="info-title">-------</p>
                           }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Transactions count</p>
                           <p className="info-response">{" Transfers = " + userData.transfers.length} {"  Topups = " +  userData.topup.length}  {"  Withdrawals = " + userData.withdrawals.length} {"  Bills Payments = " + userData.billPayment.length}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">BVN</p>
                            {userData.BVN &&
                             <p className="info-response">{'submitted'}</p>
                            }
                        </div>

                        {userData.accountOfficerData &&
                          userData.accountOfficerData.rejectedBy &&
                           <div className="user-data-info">
                              <p className="info-title">Rejected By</p>
                              {userData.accountOfficerData.rejectedBy &&
                              <p style={{color:'#CA4063'}}
                                className="info-response">{userData.accountOfficerData.rejectedBy }</p>
                              }
                           </div>
                        }
                        
                        {userData.accountOfficerData &&
                           <div className="user-data-info">
                              <p className="info-title">Approved By</p>
                              {userData.accountOfficerData.approvedBy &&
                              <p style={{color:'#325739'}}
                                className="info-response">{userData.accountOfficerData.approvedBy }</p>
                              }
                           </div>
                        }

                        {userData.accountOfficerData &&
                           <div className="user-data-info">
                              <p className="info-title">Verified By</p>
                              {userData.accountOfficerData.verifiedBy &&
                              <p style={{color:'#274E79'}}
                                className="info-response">{userData.accountOfficerData.verifiedBy }</p>
                              }
                           </div>
                        }
                        <div className="user-data-info">
                           <p className="info-title">Date Of Birth</p>
                            {userData.BVN &&
                             <p className="info-response">{userData.BVN.formatted_dob}</p>
                            }
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Transfer Limit</p>
                           <CurrencyFormat value={userData.transfer_limit} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Withdrawal Limit</p>
                           <CurrencyFormat value={userData.withdrawal_limit} displayType={'text'} thousandSeparator={true} prefix={'N'} />
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Date Registered</p>
                           <p className="info-title">{moment(userData.date_created).format('DD MMM YYYY')}</p>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Bank accounts</p>
                           <div>
                            {userData.bank_accounts.map(bank => {
                               return (
                                 <div key={bank._id} className="info-response">
                                    <p className="info-title">{bank.bank_name}</p>
                                    <p className="info-title">{bank.bank_account_number}</p><br></br>
                                 </div>
                               )
                            })}
                            </div>
                        </div>

                        <div className="user-data-info">
                           <p className="info-title">Cards</p>
                           <div>
                            {userData.cards.map(card => {
                               return (
                                 <div key={card._id} className="info-response">
                                    <p className="info-title">{card.card_type}</p>
                                    <p className="info-title">{"**** **** **** " + card.last_four_digit}</p><br></br>
                                 </div>
                               )
                            })}
                            </div>
                        </div>             

                   </div>

                   <div className="user-identity-card-wrapper">
                         { userData.identify_copy_img ? 
                            <img style={{objectFit: 'contain', width:'90%'}}
                            src={"https://dodo-tech-id.s3.us-east-2.amazonaws.com/" + userData.identify_copy_img} />
                            :
                            <img style={{objectFit: 'conver', opacity:0.3}}
                            src="/user-id.jpg" />
                         }
                   </div>
                </div>

                
            </div>
               
        )
    }

}
