
import React, {Component} from 'react';

const MyElement = ({isProduction}) => (<div>Environment: {isProduction?'Production':'Development'}</div>);
const MyElementEl = React.createElement(MyElement);
    const isProduction = (MyElementEl.type.name !== 'MyElement');

    if(isProduction) console.log("Production");
    else console.log("Development");

export const isDev = !isProduction;
export const IMAGE_BASE  = "https://oyola.s3.amazonaws.com/";
export const API_URL = !isProduction? "http://localhost:4000/api/v1" : "https://console.prod.boldbank.app/api/v1";
export const config = {
            headers: {
            "deviceid": localStorage.getItem('deviceID' + API_URL),
            Authorization: `Bearer ${localStorage.getItem('token' +  API_URL)}`,
            "apikey": isProduction ? 
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdsYWRAYm9sZHN3aXRjaC5uZyIsIm5hbWUiOiJBa2hpc29uIiwiaWF0IjoxNzIyNjk3NjMwLCJleHAiOjEuMmUrNDh9.VOY-LScKtv9BWMr2ahBCVhMEjEj_f7WEeSSkUIc5Acg"
            :
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1hbGlrQGdtYWlsLmNvbSIsIm5hbWUiOiJNYWxpayIsImlhdCI6MTY5ODQ5OTk0MCwiZXhwIjoxLjJlKzM5fQ.RoGeBYXboWAWFMUrR6Q2_NLEjuaQ2dGoLMHfbDtr-Kk"
       },
} 
    
