
import axios from 'axios';
import {API_URL, config} from '../config/baseUrl';

export const redirect = async () => {
      try {
        console.log("token", localStorage.getItem('token'))
        if(!localStorage.getItem('token')) return null;
        const response =  await axios.get(API_URL + '/admin/data', config);
        if(response.data.message == "Token Expired") {
            return "logout"
        }
      } catch (error) {
            if(error.response.data.message == "Token Expired") {
                return "redirect"
            }
      }
}

