import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {API_URL, config, isDev} from '../../config/baseUrl';
import axios from 'axios';
import './SideBar.css';

export default class SideBar extends Component {

  logOut = () => {
      localStorage.setItem('loggedIn', "no");
      window.location.reload();
  }

  state = {
    userStatusProcessing: 0,
    userStatusApproved: 0,
    saversProcessing: 0,
    loading: true,
  }

  componentWillMount(){
    setInterval( () => {
      this.getUserData()
    }, 20000);
  }



  getUserData = async () => {
    try {
      this.setState({loading: true})
        const processing = await axios.get(API_URL + '/user/all/0/10?accountUpgradeStatus=processing', config)
        const approved = await axios.get(API_URL + '/user/all/0/10?accountUpgradeStatus=approved', config)
        const savings = await axios.get(API_URL + '/user/saving/0/20?status=CLOSING', config);

       //console.log("status length", approved.data.users.length, processing.data.users.length, savings.data.savings.length)
        this.setState({
          userStatusApproved: approved.data.users.length, 
          userStatusProcessing: processing.data.users.length,
          saversProcessing: savings.data.savings.length,
          loading: false
        })
    } catch (error) {
        console.log('error ' + error);
        if(error.response && error.response.data.message == "Token Expired") {
          localStorage.setItem('loggedIn', "no");
          window.location.reload();
        }    
    }
   
}

  render() {
    const adminData =  JSON.parse(localStorage.getItem("userData" + API_URL));
        return (
            <div className="sidebar-wrapper">
               <div className="menu-wrapper">
                    <Link to="/">
                      <button className="menu-item">
                        Dashboard
                      </button>
                    </Link>
                    <Link to="/transactions">
                      <button className="menu-item">
                        Transactions
                      </button>
                    </Link>
                    <Link to="/verifications">
                      {this.state.loading && <p class="animated-gradient"></p>}
                      <button className="menu-item ">
                        Verifications
                        {!this.state.loading && <p className="menu-item-counter">{this.state.userStatusProcessing}</p>}
                      </button>
                    </Link>
                    <Link to="/approved">
                    {this.state.loading && <p class="animated-gradient"></p>}
                      <button className="menu-item">
                        Approved
                        {!this.state.loading && <p className="menu-item-counter" style={{backgroundColor:'#66a9e9'}}>
                           {this.state.userStatusApproved}</p>}
                      </button>
                    </Link>
                    <Link to="/analytics">
                      <button className="menu-item analytics-menu-btn">
                        Analytics
                      </button>
                    </Link>
                    <Link to="/Refferals">
                      <button className="menu-item">
                        Referals Data
                      </button>
                    </Link>

                    <Link to="/savings">
                     {this.state.loading && <p class="animated-gradient"></p>}
                      <button className="menu-item savings-menu-btn">
                        SuperSavers
                        {!this.state.loading && <p className="menu-item-counter" 
                        style={{backgroundColor:'#a3ffef', color: '#17133B', border: "5px solid #D6E9E5"}}>
                        {this.state.saversProcessing}</p>}
                      </button>
                    </Link>

                    <Link to="/loan">
                      <p className="menu-item email-menu-btn">
                        <span>Loans</span>
                        <img src="/email-icon.png" />
                      </p>
                    </Link>

                    {adminData.level >= 3 &&
                      <Link to="/admin">
                        <p className="menu-item email-menu-btn">
                          <span>Admin Management</span>
                          <img src="/email-icon.png" />
                        </p>
                       </Link>
                    }

                    <div className="menu-bottom-div">
                        <button onClick={this.logOut} className="menu-item">
                           Logout
                        </button>
                    </div>
               </div>
               
            </div>
        )
    }

}
