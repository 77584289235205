import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { Dots, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import axios from 'axios';
import Login from '../Login/Login';
import {API_URL, config} from '../../config/baseUrl';
import CustomSelect from '../CustomSelect/CustomSelect';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

import './Refferals.css';


var REFERENCE = moment();
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

export default class Refferals extends Component {

    state = {
       loading: true,
       allUsersList: [],
       usersList: [],
       queryString: '',
       isLoggedIn: true,
       usersCount: 0,
       transactionsCount: 0,
       verifiedUserCount: 0,
       searchLoading: false,
       isOpen: false,
       selectedData: {value: 'allTime', label: "All time users"},
       loadLimit: 5000,
    }

    componentWillMount(){   
        this.getRefferals();
    }

    getRefferals = async () => {
        try {
            const response = await axios.get(API_URL + '/user/refferal/0/20', config);
            if(response.data.message == "success") {
               // console.log("refferals", response.data.refferals)
            const sortedByRefs = await response.data.refferals.sort((a, b) => b.refferalCount - a.refferalCount);
                 console.log('sortedByRefs', sortedByRefs.length)
            this.setState({
                usersList: sortedByRefs, 
                allUsersList: sortedByRefs, 
                loading:false, 
            })
          }else {
            this.setState({loading: false})
            console.log('failed ' + response.data);
            }
        } catch (error) {
            //this.logOut()
            console.log('getRefferals ' + error);
        }
       
    }


    handleChange = (event) => {
        this.setState({queryString: event.target.value});
        if (event.target.value == '') {
            this.setState({usersList: this.state.allUsersList});
        }
      }

      logOut = () => {
        localStorage.clear();
         window.location.reload();
      }

    
    isToday = (momentDate) => {
        //console.log(momentDate)
        return moment().isSame(momentDate, 'day'); 
    }

    isYesterday = (momentDate) => {
        //console.log(moment(momentDate).subtract(1, 'days').startOf('day'))
        return moment(momentDate).subtract(2, 'days') && moment(momentDate).isBefore(moment(), 'day');
    }
  
    isWeek= (momentDate) => {
     //console.log(momentDate)
     return moment().isSame(momentDate, 'week'); 
    }

    isMonth= (momentDate) => {
        //console.log(momentDate)
        return moment().isSame(momentDate, 'month'); 
       }

    render() {
          
          const usersList = this.state.usersList.map(user => {
              return (
                    <ul key={user._id} className="list-contents">
                        <li className="list-items">
                            {user.firstName == null ? "Anonymouse" : user.firstName + " " + user.lastName}
                        </li>
                        <li className="list-items" style={{paddingLeft:40}}>
                            {user.accountNumber}
                        </li>
                        <li className="list-items" style={{paddingLeft:30}}>
                            {user.status}
                        </li>
                        <li className="list-items" style={{fontWeight: 700, color: 'green'}}>
                            <p> <CurrencyFormat value={user.bonus.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                        </li>
                        <li className="list-items">
                            <p> <CurrencyFormat value={user.balance.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'NGN '} /></p>
                        </li>
                        <li className="list-items" style={{fontWeight: 700, color:'blue'}}>
                             {user.refferalCount}
                        </li>
                        <li className="list-items-title" style={{fontSize: 9, width: 80, color:'#777'
                             }}>
                            {user.refCode}
                        </li>
                   </ul>
              )
          }) 

        return (

            this.state.loading ? 
              <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                  <Spinner color="#47c494" size={30}/> 
                  <p style={{color: '#1a2d6e', fontSize: 12, marginLeft: -17, marginTop: 12}}>
                      {this.state.loadLimit > 100 ? "Sorting datas..." : ''}</p> 
              </div>          
             :
            <div className="main-content-wrapper">
                <div className="content-title-wrap">
                    <p style={{fontSize:12, color:'#999', paddingLeft:10,}}>Refferals</p>
                </div> 
                    
                <div className="row-title" >
                    <p style={{fontWeight:500, color:'#101177'}}>Users Refferals Data</p>
                </div>

                <div className="list-container" >

                    <ul className="list-contents-title">
                        <li className="list-items-title">
                            Fullname
                        </li>
                        <li className="list-items-title" style={{paddingLeft:40}}>
                            Account No.
                        </li>
                        <li className="list-items-title" style={{paddingLeft:30}}>
                            Status
                        </li>
                        <li className="list-items-title">
                            Rewards
                        </li>
                        <li className="list-items-title">
                            Balance
                        </li>
                        <li className="list-items-title">
                            No. Referals
                        </li>
                        <li className="list-items-title" style={{width: 80, color:'#fff'}}>
                            Code
                        </li>
                    </ul>
                     
                     {this.state.searchLoading ?
                        <div style={{position: 'relative', marginLeft: '55%', marginTop: '15%'}}>
                          <Spinner color="#47c494" size={20}/>   
                        </div> 
                        :
                       usersList
                     }
                    
                </div>

            </div>
               
        )
    }

}
